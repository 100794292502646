<template>
    <MainFunctions :buttons="permitLogisticsButtons" :title="$t('menu.nav-logistic-title')"></MainFunctions>
</template>

<script>
import store from '@/store'
import { HOMETYPES } from '@/utils/utils'
import MainFunctions from "@/components/MainFunctions"

export default {
    name: 'LogisticsMenu',
    components: {
        MainFunctions
    },
    data() {
        return {
            buttons: [
                //{ title: '投件', color: "success", icon: 'move_to_inbox', routeName: 'logisticsPutin'},
                { title: '清出客件', color: "purple darken-2", icon: 'cleaning_services', routeName: 'logisticsRetrieve'},
                { title: '清店員件', color: "blue darken-2", icon: 'output', routeName: 'logisticsClerkRetrieve'},
                { title: '檢查鎖定', color: "red accent-2", icon: 'key_off', routeName: 'logisticsLockDoor'},
                { title: '待取核對', color: "grey darken-2", icon: 'checklist', routeName: 'logisticsPendingRelease'}
            ]
        }
    },
    computed: {
        permitLogisticsButtons() {
            const user = this.$store.getters.user;
            if (store.getters.pageHome === HOMETYPES.STORE || user.id === 116989) {
                const permits=["logisticsLockDoor"];
                return this.buttons.filter(a=>permits.includes(a.routeName));
            } else if (store.getters.pageHome === HOMETYPES.WASH || store.getters.pageHome === HOMETYPES.TRAVELDOCS) {
                const permits=["logisticsRetrieve","logisticsClerkRetrieve","logisticsLockDoor"];
                return this.buttons.filter(a=>permits.includes(a.routeName));
            } else if (user.isStaff) {
                const permits=["logisticsRetrieve","logisticsLockDoor"];
                return this.buttons.filter(a=>permits.includes(a.routeName));
            } else {
                const permits=["logisticsRetrieve","logisticsLockDoor","logisticsPendingRelease"];
                return this.buttons.filter(a=>permits.includes(a.routeName));
            }
        }
    }
}
</script>
